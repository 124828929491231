// Generated by Framer (a4439fa)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cHszXJBU_"];

const serializationHash = "framer-S689G"

const variantClassNames = {cHszXJBU_: "framer-v-fqrwas"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cHszXJBU_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-fqrwas", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cHszXJBU_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.2px", "--framer-line-height": "2em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(102, 102, 102))"}}>Schedule an intro</motion.p></React.Fragment>} className={"framer-13e0ju2"} data-framer-name={"Schedule an intro"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"HS6LUh9gZ"} style={{"--extracted-r6o4lv": "rgb(102, 102, 102)", "--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S689G.framer-1782p71, .framer-S689G .framer-1782p71 { display: block; }", ".framer-S689G.framer-fqrwas { height: 30px; overflow: visible; position: relative; width: 122px; }", ".framer-S689G .framer-13e0ju2 { -webkit-user-select: none; flex: none; height: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 122
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbKbrAiuQQ: React.ComponentType<Props> = withCSS(Component, css, "framer-S689G") as typeof Component;
export default FramerbKbrAiuQQ;

FramerbKbrAiuQQ.displayName = "Schedule an intro";

FramerbKbrAiuQQ.defaultProps = {height: 30, width: 122};

addFonts(FramerbKbrAiuQQ, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})